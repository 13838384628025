.gallery-list {
    max-width: 95%;
    margin: 0 auto;
    padding: 0;
    list-style: none;
}

.gallery-item {
    display: flex;
    align-items: flex-end;
    position: relative;
    max-width: 50em;
    max-height: auto;
    margin: 10em auto;
    &:first-of-type {
        margin-top: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    img {
        width: auto;
        height: auto;
    }
}

.gallery_image-info_wrap {
    display: flex;
    letter-spacing: 0.5px;
}

.gallery_image-title {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0.25em;
}

.gallery_image-information {
    display: none;
    background-color: rgba($color-white, 0.75);

}

.gallery_image-information-display {
    display: block;
    font-size: 0.9em;
    max-width: 70%;
    min-height: 10.25em;
    margin-top: 1em;

    p:first-of-type {
        margin-top: 0;
    }

    @include mq(tablets) {
        max-width: 100%;
        margin-top: 0;
        margin-left: 1em;
    }

    @include mq(wide_tablets) {
        margin-left: auto;
    }

}

.gallery_img-flex_wrap {
    @include mq(tablets) {
        display: flex;
        flex-flow: row-wrap;
        width: 100%;
    }
}

.gallery_img-info {
    @include mq(tablets) {
        width: 45%;
    }
}

.gallery-info_list {
    list-style: none;
    padding: 0;
}

.icon-info {
    display: none;
    width: 100%;
    margin-right: 1em;
    text-decoration: none;

    @include mq(wide_tablets) {
        width: 74%;
        margin-right: 0;
    }
}

.icon-info svg {
    position: absolute;
    left: 0;
    bottom: -1.75em;
    width: 1.5em;
    height: 1.5em;
    fill: $color-orange;
}

.display {
    display: block;
}

.z-index {
    z-index: -1;
}

.gallery_return-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gallery-return_icon {
    text-align: right;
    svg {
        width: 2.5em;
        height: 2.5em;
        fill: $color-orange;
    }
}

.gallery-city {
    color: $color-orange;
}

.gallery-sold {
    color: $color-red;
}