h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    color: $color-orange;
}

h1 {
    letter-spacing: 1.2px;

    @include mq(tablets) {
        font-size: 2.8em;
    }
}

h2 {
    font-size: 2.4em;
    margin-bottom: 0.5em;
}

h4 {
    font-size: 1.2em;
    margin-bottom: 0.25em;
}