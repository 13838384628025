button, .button {
	padding: 0.5em 0.75em;
	text-decoration: none;
	color: $color-white;
	transition: all 1s ease;
	background: $color-orange;
	border: none;
	&:hover {
		border-color: $color-orange;
		border-bottom-color: lighten($color-orange, 25%);
		color: lighten($color-orange, 70%);
		background-color: rgba($color-orange, 0.8);

	}
}

.button {
    display: inline-block;
}

.button-wrap {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2em;
    text-align: center;
}