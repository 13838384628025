.subnav {
    max-width: $max-width;
    margin: 2em auto 2em;
    padding: 1em 0;
    list-style: none;
    text-align: center;
    border-top: 1px solid $color-grey-light;
    border-bottom: 1px solid $color-grey-light;
}

.subnav-item {

    margin-bottom: 1em;
    &:last-of-type {
        margin-bottom: 0.5em;
    }

    a {

        text-decoration: none;
        border-bottom: 1px solid $color-grey-light;
        transition: all 0.25s ease;
        &:hover {
            color: $color-grey-dark;
            transition: all 0.25s ease;
        }
    }

    @include mq(30em) {
        display: inline-block;
        margin-right: 3em;
        margin-bottom:0;
        &:last-of-type {
            margin-right: 0;
        }
    }
}