.footer-wrap {
	border-top: 1px solid $color-grey-light;
	background-color: $color-grey-dark;
	color: $color-white;
	.footer-heading {
		padding-bottom: 0.25em;
		color: $color-grey-light;
		border-bottom: 2px solid #af5136;
	}
	.footer-heading-grey {
		border-color: $color-orange;
	}
}

.footer {
	max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

	@include mq($max-width) {
		padding: 1em 0;
	}
}

.footer-flex_line {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	width: 100%;

	@include mq(tablets) {
		width: 100%;
	}

	.flex-child-48 {
		&:first-of-type {
			margin-right: 0;
		}
		&:nth-of-type(2n) {
			@include mq(wide_tablets) {
				text-align: right;
			}
		}
	}

	a {
		color: $color-grey-light;
		text-decoration: none;
		transition: all 0.25s ease;
		&:hover {
			text-decoration: underline;
		}
	}
}

.footer-contact_link {
	padding-left: 1em;
}

.footer-social_wrap {
	display: flex;
	max-width: 30em;
	margin: 2em 0;
	svg {
		width: 2em;
		height: 2em;
		fill: $color-grey-light;
	}
}

.footer-social-link {
	margin-right: 0.75em;
	transition: 0.5s cubic-bezier(0.680, -0.550, 0.895, 1.450);
	&:hover {
		transform: translateY(-1em);
		transition: 0.5s cubic-bezier(0.000, -0.460, 0.500, 1.515);
		text-decoration: none;
	}
}

.footer-copyright-wrap {
    font-size: 0.9em;
    margin-top: 2em;
    text-align: center;
}