.herobar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1em auto 0;
    padding: 17em 0;
    background-repeat: no-repeat;
    @include mq(tablets) {
        margin: 5.5em auto 2em;
    }

    @include mq(wide_tablets) {
        width: 100%;
        background-size: 77%;
    }

    @include mq($max-width) {
        background-attachment: fixed;
    }
}

.imgbar {
    max-width: $max-width;
    margin: 0 auto 2em;
    padding: 17em 0;
    background-size: contain;
    background-position: center;

}

.herobar-heading {
    margin: 0;
    color: $color-white;
}


.herobar-hp {
    display: block;
    margin-bottom: 4em;
    background: $color-white url('/images/herobar-hp.jpg') no-repeat;

    background-position: center;
    background-size: contain;

    height: 15.5em;
    padding: 0;

    @include mq(tablets) {
        margin-top: 4em;
    }

    @include mq(wide_tablets) {
        width: 100%;
        max-width: 60em;
        height: 75vh;
        margin-top: 6em;
        background-size: 77%;
    }

    @include mq($max-width) {
        background-attachment: fixed;
    }

}

.herobar-contact {
    background: #fff url('/images/herobar-contact.jpg') no-repeat;
}

.herobar-about {
    background: #fff url('/images/herobar-about.jpg') no-repeat;
}

.herobar-work {
    background: #fff url('/images/herobar-work.jpg') no-repeat;
}

.herobar {
    background-size: contain;
    background-position: center;
}

.herobar-secondary {
    max-width: 66em;
    margin: 5em auto 0;
}

.herobar-child {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    height: 100%;
    margin: 0 auto;
    text-align: center;
}

.hp_herobar-title {
    display: none;

    @include mq(wide_tablets) {
        display: block;
    }
}

.herobar-logo {
    transition: all 1s ease;
}

.js-opacity {
    transition: all 1s ease;
    opacity: 0;
}

.opacity {
    opacity: 1;
    transform: translateX(0);
    transition: all 1.5s ease;
}