.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    @include mq(73.25em) {
        padding: 1em 0;
    }

    &-narrow {
        max-width: 40em;
    }

    &-flex {
        @include mq(wide_tablets) {
            display: flex;
            justify-content: space-between;
        }
    }

    &-no_hero {
        margin-top: 5.8em;
    }
}

.content-copy_wrap {
    @include mq(wide_tablets) {
        flex-basis: 55%;
        width: 55%;
        max-width: 55%;
        margin-right: 4%;
    }
    p:first-child {
        margin-top: 0;
    }

}

.content-img_wrap {
    text-align: center;
    @include mq(wide_tablets) {
        flex-basis: 42%;
        width: 42%;
        max-width: 42%;
        text-align: right;
    }
}

.content-img-center {
    display: block;
    margin: 1em auto;
}
.content-img-left {
    float: left;
    margin: 1em 1em 1em 0;
}
.content-img-right {
    float: right;
    margin: 1em 0 1em 1em;
}

.flex-child-48 {
    flex: 0 0 48%;
    &:first-of-type {
        margin-right: 2em;
    }
    p:first-child, h4:first-child {
        margin-top: 0;
    }
    &.-text-right {
        img {
            width: 100%;
        }
    }
}

.opacity--0 {
    opacity: 0;
    transition: all 0.5s ease;
}

.tagline {
    //default needed to override parent -text-right
    text-align: left;
    font-size: 0.9em;
    color: $color-orange;
}