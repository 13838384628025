.hp-exhibit_info {
	width: 42%;
	background-color: rgba($color-white, 0.6);
	color: #000;
	padding: 1em;
	h1, h2, h3, h4 {
		margin: 0 0 0.25em;
	}

	p {
		margin: 0.25em 0;
	}
}