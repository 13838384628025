// mixins + normalize + common
@import "common/vars",
    "common/mixins",
    "public/mixins",
    "common/media",
    "common/common";

// global styles on the most common elements
html {
    @include default-html-style;
}

a {
    color: $color-orange;
    transition: color 0.25s ease;
    &:hover { }
}
address { font-style: normal; }


// load common styles
@import "common/forms",
    //"common/pagination",
    "common/alerts",
    //"common/modal",
    "common/ie8_warning";

// public only styles
@import "public/content",
    "public/buttons",
    "public/headings",
    "public/header",
    "public/footer",
    "public/herobar",
    "public/gallery",
    "public/subnav"
    ;

// page specific
@import "public/p_index", "public/p_contact" ;