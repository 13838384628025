/* global variables */
$max-width: 66em;
$max-width-narrow: 46em;

$color-white: rgb(255, 255, 255);
$color-grey-light: rgb(200, 200, 200);
$color-grey-dark: rgb(51, 51, 51);
$color-red: rgb(247, 81, 70);

$color-orange: rgb(100, 100, 100);

$text-color: #444;
$link-color: $color-red;
$headings-color: $link-color;

$border-radius: 2px;

$status--success: rgb(90, 182, 90);
$status--info: rgb(91, 192, 221);
$status--warning: rgb(240, 173, 78);
$status--danger: rgb(217, 82, 78);

$pagination-link-hover: rgba(32, 36, 41, 0.1);
$pagination-current-border: rgba(32, 36, 41, 0.15);
$pagination-item-inactive: lighten($text-color, 50%);

$mqs: (
    handhelds: 20em,
    wide_handhelds: 35em,
    tablets: 48em,
    wide_tablets: 64em,
    // hero & image bars
    hero_medium: 25em,
    hero_large: 50em,
    hero_extra_large: 68.75em
);