.header-wrap {
    width: 100%;
    background-color: $color-white;
    z-index: 100;
    border-bottom: 1px solid rgba($color-grey-light, 0.8);
    transition: all 0.5s ease;

    @include mq(wide_tablets) {
        position: fixed;
        top: 0;
    }
}

.header-wrap-scroll {
    transition: all 0.5s ease;
    background-color: rgba($color-white, 0.8);
}

.header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-width: $max-width;
    margin: 0 auto;
}

.header-nav {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-bottom: 0.5em;
    padding: 0 1em;
    font-weight: 300;
    list-style: none;
    @include mq(wide_tablets) {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }
}

.header-nav_item {
    display: block;
    letter-spacing: 1px;
    text-align: center;
    order: 2;
    margin-bottom: 0.5em;

    &:last-of-type {
        margin-right: 0;
    }

    a {
        text-decoration: none;
        color: $color-grey-dark;

        &:hover {
            color: $color-orange;
        }
    }

    @include mq(wide_tablets) {
        display: inline-block;
        font-size: 1em;
        margin-bottom: 0;
    }

    &-logo {
        order: 1;
        @include mq(wide_tablets) {
            order: 2;
            width: 12em;
        }
        @include mq(wide_tablets) {
            margin: 0;
        }
    }
}